import {graph2, api} from '../utils/shared'

export const bb_name_from_code = (bb_code) => {

    if(bb_code == 5){
        return 'All inclusive';
    }
    else if(bb_code == 4){
        return 'Full board';
    }
    else if(bb_code == 3){
        return 'Half board';
    }
    else if(bb_code == 2){
        return 'Bed & Breakfast';
    }
    else if(bb_code == 1){
        return 'Room only';
    }
    else{
        return 'n/a';
    }
    
};

export const accommodation = async(accommodation_id, fields, token) => {
    let q = `{
        accommodation(accommodation_id: ${accommodation_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const accommodation_total_view_count = async(date_from=null, date_to=null, prod_type=null, token) => {

    const pd = {
        "date_from": date_from,
        "date_to": date_to,
        "type": prod_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/accommodation_total_view_count/", pd,
            "POST",
            (d) => {
                d.text().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const accommodation_views = async(destinationm_ids=null, region_ids=null, date_from=null, date_to=null, limit=8, prod_type=null, token) => {


    const pd = {
        "destination_ids": destinationm_ids,
        "region_ids": region_ids,
        "date_from": date_from,
        "date_to": date_to,
        "limit": limit,
        "type": prod_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/accommodation_view_count/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}
export const accommodation_count_by_month = async(destinationm_ids=null, region_ids=null, date_from=null, date_to=null, limit=100, prod_type=null, token) => {


    const pd = {
        "destination_ids": destinationm_ids,
        "region_ids": region_ids,
        "date_from": date_from,
        "date_to": date_to,
        "limit": limit,
        "type": prod_type
    }

    return new Promise(async (resolve, reject) => {
        await api(
            "/accommodation_count_by_month/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const all_accommodation_geo = async(token) => {

    token = process.env.REACT_APP_GRAPHQL_BEARER_ENG;

    let q = `{all_destinations_regions_resorts_acc {result}}`
    let x = await graph2(q, token)
    return x
}
